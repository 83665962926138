Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("testimonials")) {
        const TestimonialSlider = new Vue({
            name: 'testimonials',
            el: '#testimonials',
            store,
            data: function () {
                return {
                    testimonialslider: null,
                    testimonialsliderNav: null
                }
            },
            computed: {},
            mounted: function () {
                this.testimonialSlider();
                this.testimonialSliderNav();
            },
            methods: {
                testimonialSlider: function () {
                    let that = this;

                    let elem = document.querySelector(".testimonials-slider");
                    this.testimonialslider = new Flickity(elem, {
                        contain: true,
                        pageDots: false,
                        cellAlign: "left",
                        imagesLoaded: true,
                        wrapAround: true,
                        prevNextButtons: false,
                        lazyLoad: 2,
                        slides: 1,
                    });
                },

                testimonialSliderNav: function () {
                    let that = this;

                    let elem = document.querySelector(".testimonials-navigation");

                    this.testimonialsliderNav = new Flickity(elem, {
                        contain: true,
                        pageDots: false,
                        cellAlign: "left",
                        imagesLoaded: true,
                        wrapAround: true,
                        prevNextButtons: false,
                        lazyLoad: 2,
                        asNavFor: '.testimonials-slider',
                        slides: 3,

                    });
                },

                nextTestimonial: function() {
                    this.testimonialslider.next(true, false);
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});

Vue.component("kor-input-element", {
    name: 'KOR-input',
    data: function () {
        return {
            active: false,
            amount: this.getAmount(),
            vat: this.getVat(),
        }
    },
    props: {
        parentamount: Object,
        section: Number,
        number: Number,
        vatrate: Number,
    },
    mounted: function () {
        this.getAmount()
        this.getVat()
    },
    watch: {
        'amount': function(val) {
            this.getVat()
            this.calculate(val)
        }
    },
    methods: {
        toggleQuestion: function() {
            this.active = !this.active
        },
        calculate: function(value) {
            let objAmount = {
                [this.section]: {
                    id: this.section +''+ this.number,
                    amount: this.amount,
                    vat: this.vat,
                }
            };

            this.$emit('updateamount', objAmount)

            this.$emit('updatedata', {
                section: this.section,
            });
        },
        getAmount: function() {
            let newAmount = this.amount
            if(typeof this.parentamount['section'+ this.section] != 'undefined' && this.parentamount['section'+ this.section].length > 0) {
                for(let key in this.parentamount['section'+ this.section]) {
                    let obj = this.parentamount['section'+ this.section][key]
                    if(obj.id == this.section +''+ this.number) {
                        return obj.amount > 0 ? obj.amount : ''
                    }
                }
            }

            return newAmount > 0 ? newAmount : ''
        },
        getVat: function() {
            if(this.amount > 0) {
                if(this.vatrate > 0) {
                    this.vat = this.amount * (this.vatrate / 100)
                }
            } else {
                this.vat = this.vat > 0 ? this.vat : ''
            }
        },
        preventWrongInput: function($event) {
            // Only accept integers and command keys like del, backspace etc.
            let e = $event

            if (e.key.length === 1 && e.key !== '.' && isNaN(e.key) && !e.ctrlKey || e.key === '.' && e.target.value.toString().indexOf('.') > -1) {
                e.preventDefault()
            }
        },
        formatPrice(value) {
            if(typeof value == 'undefined') {
                return ''
            }

            const formatter = new Intl.NumberFormat('nl-NL')
            return formatter.format(Math.round(value))
        },
    }
});

Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("counter")) {
        const valuesSlider = new Vue({
            name: 'counter',
            el: '#counter',
            store,
            data: function () {
                return {}
            },
            computed: {},
            mounted: function () {
                this.runCounter();
            },
            methods: {
                runCounter: function () {


                    var animated = false;
                    var screensize = window.innerHeight;

                    window.onscroll = function () {
                        if (!animated) {

                            var viewport_bottom = window.pageYOffset + screensize;
                            var viewport_top = window.pageYOffset;
                            var elm_bottom = document.getElementById("counter").getBoundingClientRect().top + document.getElementById("counter").offsetHeight;

                            if (viewport_top < elm_bottom && viewport_bottom > elm_bottom) {
                                animated = true;

                                document.querySelectorAll(".counter__value").forEach(function (obj) {

                                    var start = 0;
                                    var end = obj.getAttribute('data-value');

                                    var duration = 1800;

                                    var current = 0;
                                    var increment = end > start ? 1 : -1;
                                    var stepTime = Math.abs(Math.floor(duration / end));

                                    var timer = setInterval(function () {
                                        current += increment;
                                        obj.innerHTML = current;
                                        if (current >= end) {
                                            clearInterval(timer);
                                        }
                                    }, stepTime);
                                });
                            }
                        }
                    };
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});

Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("cultural-values-slider")) {
        const valuesSlider = new Vue({
            name: 'cultural-values-slider',
            el: '#cultural-values-slider',
            store,
            data: function () {
                return {
                    culturalValuesSlider: null
                }
            },
            computed: {},
            mounted: function () {
                this.initializeCulturalValuesSlider();
            },
            methods: {
                initializeCulturalValuesSlider: function () {
                    let that = this;

                    let elem = document.querySelector(".cultural-values");
                    this.culturalValuesSlider = new Flickity(elem, {
                        contain: true,
                        pageDots: false,
                        imagesLoaded: true,
                        cellAlign: "left",
                        wrapAround: true,
                        prevNextButtons: false,
                        adaptiveHeight: false,
                        slides: 1,
                        lazyLoad: true
                    });
                },
                nextCulturalValuesSlide: function() {
                    this.culturalValuesSlider.next(true, false);
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});

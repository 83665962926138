Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("section-video") && document.getElementById("section-video-main")) {
        const dualVideo = new Vue({
            name: 'section-video',
            el: '#section-video',
            store,
            data: function () {
                return {
                    mainVideo: {},
                }
            },
            computed: {},
            mounted: function () {
                this.initiateVideos();
            },
            methods: {
                initiateVideos: function () {
                    let that = this;

                    this.mainVideo.instance = videojs('#section-video-main', {
                        controls: true,
                        fluid: true,
                        aspectRatio: '16:9',
                        techOrder: ["html5", "Youtube"],
                    });

                    Barba.Dispatcher.on("linkClicked", function () {
                        setTimeout(() => {
                            that.mainVideo.instance.dispose();
                        }, 100);
                    });
                },
                toggleVideo: function (playerID) {
                    let elem = document.getElementById(playerID).parentElement;
                    elem.classList.toggle('video__wrapper--playing');

                    if (!this.mainVideo.playing) {
                        this.mainVideo.instance.play();
                        this.mainVideo.instance.controls(true);
                        this.mainVideo.playing = true;
                    } else {
                        this.mainVideo.instance.pause();
                        this.mainVideo.instance.controls(false);
                        this.mainVideo.playing = false;
                    }
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});

Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("blog-filter")) {
        const blogFilter = new Vue({
            name: 'blog-filter',
            el: '#blog-filter',
            store,
            data: function () {
                return {}
            },
            computed: {
                activeBlogFilterDiscipline: {
                    get: function () {
                        return this.$store.getters.activeBlogFilterDiscipline
                    },
                    set: function (newValue) {
                        this.$store.dispatch('setActiveBlogFilterDiscipline', newValue)
                    }
                }
            },
            mounted: function () {
            },
            methods: {
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});

Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("form-apply")) {
        const form = new Vue({
            name: 'form-apply',
            el: '#form-apply',
            store,
            data: function () {
                return {
                    applyOption: "linkedin",
                    hasCaptcha: false,
                    captchaExecuted: false,
                    captchaEvent: null,
                }
            },
            watch: {
            },
            components: {
                'vue-recaptcha': VueRecaptcha
            },
            computed: {
                opstellenRules() {
                    return this.applyOption === "opstellen" ? 'required' : '';
                },
                linkedinRules() {
                    return this.applyOption === "linkedin" ? { required: true, regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/  } : '';
                },
                uploadRules() {
                    return this.applyOption === "upload" ? 'required' : '';
                }
            },
            mounted: function () {
            },
            methods: {
                submit: function ($event) {
                    let that = this;

                    this.$validator.validateAll().then(result => {
                        let analytics = $event.target.dataset.analytics;

                        if (result) {
                            if (!this.hasCaptcha || this.captchaExecuted) {
                                Global.SubmitForm($event.target, analytics);
                                that.captchaEvent = null;
                            } else {
                                that.captchaEvent = $event;
                                this.$refs.recaptcha.execute();
                            }
                        }
                    });
                    this.$refs.recaptcha.reset();
                    this.captchaExecuted = false;
                },
                onCaptchaVerified: function () {
                    this.captchaExecuted = true;
                    this.submit(this.captchaEvent);
                },
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});

Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("mafWrapper")) {
        const mapWrapper = new Vue({
            name: 'maf',
            el: '#mafWrapper',
            store,
            data: function () {
                return {
                    percent: 0,
                    amount: 0
                }
            },
            computed: {},
            mounted: function () {
                this.animatePercent();
                this.animateAmount();
            },
            methods: {
                animatePercent: function () {
                    let that = this;

                    let elem = document.querySelector(".maf__progress");
                    let perc = elem.getAttribute('data-percent');

                    let intvalperc = setInterval(function () {
                        if (that.percent >= perc) {
                            clearInterval(intvalperc);
                        } else {
                            that.percent++;
                            elem.setAttribute('data-percent', that.percent);
                        }
                    }, 10);
                },
                animateAmount: function () {
                    let that = this;

                    let elem = document.querySelector(".maf__progress");
                    let amount = elem.getAttribute('data-amount');
                    let steps = (amount / 20);

                    let intvalamount = setInterval(function () {
                        if (that.amount >= amount) {
                            clearInterval(intvalamount);
                        } else {
                            if (steps + that.amount > amount) {
                                that.amount = amount;
                            } else {
                                that.amount += steps;
                            }

                            elem.setAttribute('data-euro', Intl.NumberFormat('nl-NL', {
                                style: 'currency',
                                currency: 'EUR',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            }).format(that.amount));
                        }
                    }, 50);
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});

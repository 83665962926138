Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("about-video")) {
        const aboutVideo = new Vue({
            name: 'about-video',
            el: '#about-video',
            store,
            data: function () {
                return {
                    aboutVideo: {},
                }
            },
            computed: {},
            mounted: function () {
                this.initiateVideos();
            },
            methods: {
                initiateVideos: function () {
                    let that = this;
                    let desktopVideo = window.outerWidth >= 1200 ? true : false;

                    if (desktopVideo) {
                        this.aboutVideo.instance = videojs('#about-video-main-desktop', {
                            controls: false,
                            autoplay: 'muted',
                            fluid: true,
                            loop: true,
                            aspectRatio: '16:9',
                            techOrder: ["html5", "Youtube"],
                        });

                        let video = that.aboutVideo.instance;
                        let videoWrapper = document.getElementById('aboutWrapper');
                        videoWrapper.addEventListener('click', function() {
                            video.requestFullscreen();
                            document.body.classList.add('fullscreen');
                        });

                        video.on('fullscreenchange', function() {
                            let fullScreen = video.isFullscreen();

                            if (fullScreen == false) {
                                document.body.classList.remove('fullscreen');
                            } else {
                                this.play();
                                document.body.classList.add('fullscreen');
                            }
                        });
                    } else {
                        this.aboutVideo.instance = videojs('#about-video-main', {
                            controls: true,
                            fluid: true,
                            aspectRatio: '16:9',
                            techOrder: ["html5", "Youtube"],
                        });
                    }

                    Barba.Dispatcher.on("linkClicked", function () {
                        setTimeout(() => {
                            that.aboutVideo.instance.dispose();
                        }, 100);
                    });
                },
                toggleVideo: function (playerID) {
                    let elem = document.getElementById(playerID).parentElement;
                    elem.classList.toggle('video__wrapper--playing');

                    if (!this.aboutVideo.playing) {
                        this.aboutVideo.instance.play();
                        this.aboutVideo.instance.controls(true);
                        this.aboutVideo.playing = true;
                    } else {
                        this.aboutVideo.instance.pause();
                        this.aboutVideo.instance.controls(false);
                        this.aboutVideo.playing = false;
                    }
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});

Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("korWrapper")) {
        const korWrapper = new Vue({
            el: '#korWrapper',
            name: 'KOR',
            data: function () {
                return {
                    section: 1,
                    question: 0,
                    message: '',
                    show: false,
                    amount: {
                        section2: [],
                        section4: [],
                        section5: [],
                    },
                    hasCaptcha: false,
                    captchaExecuted: false,
                }
            },
            components: {
                'vue-recaptcha': VueRecaptcha
            },
            computed: {},
            mounted: function () {
                this.show = true
            },
            watch: {
                'amount.section2': {
                    handler: function() {
                        this.calculateSubTotal()
                        this.calculateVatTotal()
                    },
                    deep: true
                },
                'amount.section4': {
                    handler: function() {
                        this.calculateSubTotal()
                        this.calculateVatTotal()
                    },
                    deep: true
                },
                'amount.section5': {
                    handler: function() {
                        this.calculateSubTotal()
                        this.calculateVatTotal()
                    },
                    deep: true
                }
            },
            methods: {
                updateData(data) {
                    if(typeof data.section != 'undefined') {
                        this.section = data.section

                        if(typeof this.amount['section'+ this.section] != 'undefined') {
                            if(this.amount['section'+ this.section].length == 9) {
                                let subTotal = this.calculateSubTotal(data.section)
                                if (subTotal <= 20000 && this.section < 5) {
                                    this.section = data.section + 1
                                } else if (this.section >= 5) {
                                    // Is OVOB compliant
                                    this.message = true

                                    this.sendGaEvent({
                                        category: 'Omzet jaar 2/3',
                                        message: 'Voldoet aan OVOB regeling'
                                    });
                                } else if (subTotal > 20000) {
                                    // Is not OVOB compliant
                                    this.message = false

                                    this.sendGaEvent({
                                        category: this.section == 2 ? 'Omzet jaar 1' : 'Omzet jaar 2/3',
                                        message: 'Voldoet niet aan OVOB regeling'
                                    });
                                }
                            } else if(this.message) {
                                this.message = ''
                            }
                        }
                    }

                    if(typeof data.question != 'undefined') {
                        this.question = data.question
                    }

                    if(typeof data.message != 'undefined') {
                        this.message = data.message
                    }
                },
                updateAmount(data) {
                    if(typeof data == 'object') {
                        for(let section in data) {
                            if(data.hasOwnProperty(section)) {
                                let obj = data[section]

                                for(let key in this.amount) {
                                    if(this.amount['section'+ section].length > 0) {
                                        for(let key in this.amount['section'+ section]) {
                                            let aObj = this.amount['section'+ section][key]
                                            if(aObj.id == obj.id) {
                                                this.amount['section'+ section].splice(key, 1)
                                            }
                                        }
                                    }
                                }

                                if(obj.amount != '') {
                                    this.amount['section'+ section].push(obj)
                                }
                            }
                        }
                    }
                },
                calculateSubTotal: function(section) {
                    let subTotal = 0
                    if(typeof this.amount['section'+ section] == 'undefined') {
                        return subTotal
                    }

                    for(let key in this.amount['section'+ section]) {
                        let obj = this.amount['section'+ section][key]
                        subTotal = subTotal + (obj.amount/1)
                    }

                    if(subTotal > 20000) {
                        // Is not OVOB compliant
                        this.message = false

                        this.sendGaEvent({
                            category: this.section == 2 ? 'Omzet jaar 1' : 'Omzet jaar 2/3',
                            message: 'Voldoet niet aan OVOB regeling'
                        });
                    } else if(this.message !== true && (this.section == 2 || this.section == 4)) {
                        this.message = ''
                    }

                    return subTotal
                },
                calculateVatTotal: function(section) {
                    let vatTotal = 0
                    if(typeof this.amount['section'+ section] == 'undefined') {
                        return vatTotal
                    }

                    for(let key in this.amount['section'+ section]) {
                        let obj = this.amount['section'+ section][key]
                        vatTotal = vatTotal + (obj.vat/1)
                    }

                    return vatTotal
                },
                getSubTotal: function(section) {
                    return this.calculateSubTotal(section)
                },
                getVatTotal: function(section) {
                    return this.calculateVatTotal(section)
                },
                formatPrice(value) {
                    const formatter = new Intl.NumberFormat('nl-NL')
                    return formatter.format(Math.round(value))
                },
                sendGaEvent(data) {
                    let category = typeof data.category != 'undefined' ? data.category : 'Algemeen'
                    let message = typeof data.message != 'undefined' ? data.message : 'Onbekend'

                    if (typeof ga === 'function') {
                        let trackers = ga.getAll();
                        trackers.forEach(function (tracker) {
                            ga(tracker.get('name') + '.send', 'event', 'KOR Toolkit', category, message, {
                                nonInteraction: true
                            })
                        })
                    }
                },
                onCaptchaVerified: function () {
                    this.captchaExecuted = true;
                }
            }
        });
    }
});

Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("milestones-slider")) {
        const milestonesSlider = new Vue({
            name: 'milestones-slider',
            el: '#milestones-slider',
            store,
            data: function () {
                return {
                    milestonesSlider: null,
                    milestonesSliderNav: null
                }
            },
            computed: {},
            mounted: function () {
                this.initializeMilestonesSlider();
                this.initializeMilestonesSliderNav();
            },
            methods: {
                initializeMilestonesSlider: function () {
                    let that = this;

                    let elem = document.querySelector(".milestones-slider");
                    this.milestonesSlider = new Flickity(elem, {
                        contain: true,
                        pageDots: false,
                        imagesLoaded: true,
                        wrapAround: true,
                        prevNextButtons: false,
                        selectedAttraction: 1,
                        adaptiveHeight: true,
                        friction: 1,
                        slides: 1
                    });
                },

                initializeMilestonesSliderNav: function () {
                    let that = this;

                    let elem = document.querySelector(".milestones-navigation");

                    this.milestonesSliderNav = new Flickity(elem, {
                        contain: true,
                        pageDots: false,
                        imagesLoaded: true,
                        wrapAround: true,
                        prevNextButtons: false,
                        asNavFor: '.milestones-slider'

                    });
                },
                nextMilestonesSlide: function() {
                    this.milestonesSlider.next(true, false);
                },
                prevMilestonesSlide: function() {
                    this.milestonesSlider.previous(true, false);
                }
            },

            created: function () {
            },

            destroyed: function () {
            }
        });
    }
});

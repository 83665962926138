Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("box3Wrapper")) {
        const box3Wrapper = new Vue({
            el: '#box3Wrapper',
            name: 'BOX3',
            data: function () {
                return {
                    section: 2,
                    question: 0,
                    message: '',
                    show: false,

                    partner: 0,

                    bezittingen2020: '0',
                    bezittingen2022: '0',
                    spaargeld2020: '0',
                    spaargeld2022: '0',
                    overig2020: '0',
                    overig2022: '0',
                    vastgoed2020: '0',
                    vastgoed2022: '0',

                    schulden2020: '0',
                    schulden2022: '0',
                    leningen2020: '0',
                    leningen2022: '0',

                    waardebezit2020: '0',
                    waardebezit2022: '0',
                    waardeschulden2020: '0',
                    waardeschulden2022: '0',
                    heffingsvrij2020: '0',
                    heffingsvrij2022: '0',

                    rendementsgrond2020: '0',
                    rendementsgrond2022: '0',

                    belastbaarinkomen2020: '0',
                    belastbaarinkomen2022: '0',

                    belastingbedrag2020: '0',
                    belastingbedrag2022: '0',

                    meerbetalen: true,

                    verschil: '0',

                    GAtimer: null,
                    GAtrigger: false,

                    hasCaptcha: false,
                    captchaExecuted: false,
                }
            },
            components: {
                'vue-recaptcha': VueRecaptcha
            },
            computed: {},
            mounted: function () {
                this.show = true
            },
            watch: {
                'spaargeld2020': {
                    handler: function() {
                        console.log('spaargeld2020');
                        this.calc();
                    },
                    deep: true
                },
                'overig2020': {
                    handler: function() {
                        console.log('overig2020');
                        this.calc();
                    },
                    deep: true
                },
                'vastgoed2020': {
                    handler: function() {
                        console.log('vastgoed2020');
                        this.calc();
                    },
                    deep: true
                },
                'leningen2020': {
                    handler: function() {
                        console.log('leningen2020');
                        this.calc();
                    },
                    deep: true
                },
            },
            methods: {
                calc(){
                    let that = this;
                    // Haal inputs op, indien geen waarde geef 0
                    // Bereken totalen die als basis dienen voor de andere berekeningen
                    // En ja dit kan slimmer, maar leesbaarheid vind ik hier belangrijker ;) -Mark
                    let partnerWaarde       = (this.partner == 1 ? 2 : 1)

                    let inputSpaargeld      = (isNaN(parseInt(this.spaargeld2020)) ? 0 : parseInt(this.spaargeld2020));
                    this.spaargeld2022      = this.formatPrice(inputSpaargeld);

                    let inputOverig         = (isNaN(parseInt(this.overig2020)) ? 0 : parseInt(this.overig2020));
                    this.overig2022         = this.formatPrice(inputOverig);

                    let inputVastgoed       = (isNaN(parseInt(this.vastgoed2020)) ? 0 : parseInt(this.vastgoed2020));
                    this.vastgoed2022       = this.formatPrice(inputVastgoed);

                    let waardebezit2020     = inputSpaargeld + inputOverig + inputVastgoed;
                    this.bezittingen2020    = this.formatPrice(waardebezit2020);
                    this.bezittingen2022    = this.formatPrice(waardebezit2020);

                    let inputLeningen       = (isNaN(parseInt(this.leningen2020)) ? 0 : parseInt(this.leningen2020));
                    this.leningen2022       = this.formatPrice(inputLeningen);
                    this.schulden2022       = this.formatPrice(inputLeningen);

                    let schulden2020        = Math.round((inputLeningen > (partnerWaarde * 3100) ? inputLeningen - (partnerWaarde * 3100) : 0));
                    this.schulden2020       = this.formatPrice(schulden2020);

                    this.waardebezit2020    = this.formatPrice(waardebezit2020);
                    let waardebezit2022     = Math.round((inputSpaargeld * 0.0009) + (0.0533 * (inputOverig + inputVastgoed)));
                    this.waardebezit2022    = this.formatPrice(waardebezit2022);

                    schulden2020            = (schulden2020 > 0 ? schulden2020 : 0);
                    let schulden2022        = Math.round(0.0303 * inputLeningen);
                    this.waardeschulden2020 = this.formatPrice(schulden2020);
                    this.waardeschulden2022 = this.formatPrice(schulden2022);
                    let heffingsvrij2020    = (waardebezit2020 > 0 ? (partnerWaarde * 30846) : 0 );
                    this.heffingsvrij2020   = this.formatPrice(heffingsvrij2020);
                    let heffingsvrij2022    = (waardebezit2020 > 0 ? (partnerWaarde * 400) : 0 );
                    this.heffingsvrij2022   = this.formatPrice(heffingsvrij2022);

                    let rendementsgrond2020 = waardebezit2020 - schulden2020 - heffingsvrij2020;
                    this.rendementsgrond2020 = this.formatPrice(rendementsgrond2020);
                    let rendementsgrond2022  = waardebezit2022 - schulden2022 - heffingsvrij2022;
                    this.rendementsgrond2022 = this.formatPrice(rendementsgrond2022);

                    let belastbaarinkomen2020 = this.belastbaarInkomenBerekening(rendementsgrond2020);
                    this.belastbaarinkomen2020 = this.formatPrice(belastbaarinkomen2020);
                    let belastbaarinkomen2022 = (rendementsgrond2022 > 0 ? rendementsgrond2022 : 0);
                    this.belastbaarinkomen2022 = this.formatPrice(belastbaarinkomen2022);

                    let belastingbedrag2020 = Math.round(belastbaarinkomen2020 * 0.3);
                    this.belastingbedrag2020 = this.formatPrice(belastingbedrag2020);
                    let belastingbedrag2022 = Math.round(belastbaarinkomen2022 * 0.33);
                    this.belastingbedrag2022 = this.formatPrice(belastingbedrag2022);

                    this.verschil = this.formatPrice(belastingbedrag2022 - belastingbedrag2020);

                    if(parseInt(this.verschil) > 0){
                        this.meerbetalen = true;
                    } else {
                        this.meerbetalen = false;
                    }

                    //GA event eenmalig verzenden
                    if(!that.GAtrigger){
                        clearTimeout(that.GAtimer);
                        that.GAtimer = setTimeout(function(){
                            that.GAtrigger = true;
                            that.sendGaEvent({
                                'category': 'BOX3 Toolkit',
                                'message': 'berekend'
                            });

                        }, 5000)
                    }

                    /*

                    */

                },
                belastbaarInkomenBerekening(inkomen) {
                    if(inkomen <= 0){
                        return 0;
                    }
                    if(inkomen > 0 && inkomen <= 72797){
                        return Math.round(((inkomen * 0.67) * 0.0006)+((inkomen * 0.33) * 0.0533));
                    }
                    if(inkomen > 72797 && inkomen <= 1005572){
                        return Math.round(((inkomen - 72797) * (0.21 * 0.0006)) + ((inkomen - 72797) * (0.79 * 0.0533)) + 1309.690827);
                    }
                    if(inkomen > 1005572){
                        return Math.round(((inkomen - 1005572) * 0.0533) + 40703.5774);
                    }
                    return 'Error';
                },

                toggleQuestion: function(event) {
                    event.target.parentNode.parentNode.classList.toggle('box3-toolkit--info');
                },
                checkQuestion: function(e) {
                    let value = e.srcElement.value;
                    console.log(this.partner);
                    this.calc();
                },

                preventWrongInput: function($event) {
                    // Only accept integers and command keys like del, backspace etc.
                    let e = $event

                    //Oude versie, punt verwijdert uit input
                    //if (e.key.length === 1 && e.key !== '.' && isNaN(e.key) && !e.ctrlKey || e.key === '.' && e.target.value.toString().indexOf('.') > -1) {
                    if (e.key.length === 1 &&  isNaN(e.key) && !e.ctrlKey || e.key === '.' && e.target.value.toString().indexOf('.') > -1) {
                        e.preventDefault()
                    }
                },

                formatPrice(value) {
                    if(typeof value == 'undefined') {
                        return ''
                    }
                    const formatter = new Intl.NumberFormat('nl-NL')
                    return formatter.format(Math.round(value))
                },

                sendGaEvent(data) {
                    let category = typeof data.category != 'undefined' ? data.category : 'Algemeen'
                    let message = typeof data.message != 'undefined' ? data.message : 'Onbekend'

                    if (typeof ga === 'function') {
                        let trackers = ga.getAll();
                        trackers.forEach(function (tracker) {
                            ga(tracker.get('name') + '.send', 'event', 'Box3 Toolkit', category, message, {
                                nonInteraction: true
                            })
                        })
                    }
                },
                onCaptchaVerified: function () {
                    this.captchaExecuted = true;
                }
            }
        });
    }
});

var store = new Vuex.Store({
    state: {
        isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
        menuExpanded: false,
        modalVisible: false,
        activeBlogFilterDiscipline: 0,
        vatIncreaseAmount: null,
        vatIncreaseForm: false,
        vatIncreaseAnswers: {},
    },
    actions: {
        toggleMenu({commit}, value) {
            commit('toggleMenu', value)
        },
        toggleModalVisibility({ commit }, value) {
            commit('toggleModalVisibility', value)
        },
        setActiveBlogFilterDiscipline({commit}, value) {
            commit('setActiveBlogFilterDiscipline', value)
        },
        setVatIncreaseAmount({commit}, value) {
            commit('setVatIncreaseAmount', value)
        },
        setVatIncreaseForm({commit}, value) {
            commit('setVatIncreaseForm', value)
        },
        setVatIncreaseAnswers({commit}, value) {
            commit('setVatIncreaseAnswers', value)
        }
    },
    getters: {
        isMobile() {
            return store.state.isMobile;
        },
        menuExpanded() {
            return store.state.menuExpanded;
        },
        modalVisible () {
            return store.state.modalVisible;
        },
        activeBlogFilterDiscipline() {
            return store.state.activeBlogFilterDiscipline;
        },
        vatIncreaseAmount() {
            return store.state.vatIncreaseAmount;
        },
        vatIncreaseForm() {
            return store.state.vatIncreaseForm;
        },
        vatIncreaseAnswers() {
            return store.state.vatIncreaseAnswers;
        }
    },
    mutations: {
        toggleMenu(state, value) {
            store.state.menuExpanded = value;
        },
        toggleModalVisibility (state, value) {
            store.state.modalVisible = value;
        },
        setActiveBlogFilterDiscipline(state, value) {
            store.state.activeBlogFilterDiscipline = value;
        },
        setVatIncreaseAmount(state, value) {
            store.state.vatIncreaseAmount = value;
        },
        setVatIncreaseForm(state, value) {
            store.state.vatIncreaseForm = value;
        },
        setVatIncreaseAnswers(state, value) {
            store.state.vatIncreaseAnswers = value;
        }
    }
});
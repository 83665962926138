Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.querySelectorAll(".card-slider-wrapper").length) {

        document.querySelectorAll(".card-slider-wrapper").forEach(elm => {
            new Vue({
                name: 'card-slider',
                el: elm,
                store,
                data: function () {
                    return {
                        slider: null
                    }
                },
                computed: {},
                mounted: function () {
                    this.initializeSlider();
                },
                methods: {
                    initializeSlider: function () {
                        let that = this;

                        let elem = this.$el.querySelector(".card-slider");
                        this.slider = new Flickity(elem, {
                            contain: true,
                            wrapAround: false,
                            pageDots: false,
                            imagesLoaded: true,
                            prevNextButtons: false,
                            cellAlign: 'left',
                            lazyLoad: 2
                        });
                    },
                    nextSlider: function() {
                        this.slider.next(true, false);
                    }
                },
                created: function () {
                },
                destroyed: function () {
                }
            });
        })

    }
});

Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("job-filter-cta")) {
        const jobFilterCta = new Vue({
            name: 'job-filter-cta',
            el: '#job-filter-cta',
            store,
            data: function () {
                return {
                    discipline: '',
                    location: ''
                }
            },
            computed: {},
            mounted: function () {
            },
            methods: {
            },
            created: function () {
            },
            destroyed: function () {
            }
        });


    }
});

Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("platformfictieWrapper")) {
        const platformfictieWrapper = new Vue({
            el: '#platformfictieWrapper',
            name: 'platformfictie',
            data: function () {
                return {
                    answers: {1: null, 2: null, 3: null, 4: null},
                    message: null,
                    show: false,
                    hasCaptcha: false,
                    captchaExecuted: false,
                    choice: null,
                }
            },
            components: {
                'vue-recaptcha': VueRecaptcha
            },
            computed: {},
            mounted: function () {
                this.show = true
            },
            methods: {
                changeRadio(number) {
                    // If you already answered later questions, empty them
                    for (const i in this.answers) if (this.answers.hasOwnProperty(i))
                        if (i > number) this.answers[i] = null
                },
                showQuestion(number) {
                    switch (number) {
                        case 2:
                            return this.answers[1] === '1'
                        case 4:
                            return this.answers[3] === '0'
                        default:
                            return this.answers[number - 1] !== null
                    }
                },
                showChoiceText() {
                    return (this.answers[4] === '1' || (this.answers[2] === '0' && this.answers[3] === '1'))
                },
                showMessage(type) {
                    switch (type) {
                        case '1_no':
                            return this.answers[1] === '0'
                        case '3_yes':
                            return (this.answers[1] === '1' && this.answers[2] === '1' && this.answers[3] === '1')
                        case '4_no':
                            return this.answers[4] === '0'
                    }
                },
                showConsequences() {
                    return (this.answers[4] === '1' || (this.answers[2] === '0' && this.answers[3] === '1'))
                },
                showPointsOfAttention(choice) {
                    return (this.answers[4] === '1' || (this.answers[2] === '0' && this.answers[3] === '1'))
                },
                sendGaEvent(data) {
                    // Disable for now
                    //
                    // let category = typeof data.category != 'undefined' ? data.category : 'Algemeen'
                    // let message = typeof data.message != 'undefined' ? data.message : 'Onbekend'
                    //
                    // if (typeof ga === 'function') {
                    //     let trackers = ga.getAll();
                    //     trackers.forEach(function (tracker) {
                    //         ga(tracker.get('name') + '.send', 'event', 'Toolkit platformfictie', category, message, {
                    //             nonInteraction: true
                    //         })
                    //     })
                    // }
                },
                onCaptchaVerified: function () {
                    this.captchaExecuted = true;
                }
            }
        });
    }
});

Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("vatincrease-tool")) {
        const vatincreaseTool = new Vue({
            name: 'vatincrease-tool',
            el: '#vatincrease-tool',
            store,
            data: function () {
                return {
                }
            },
            computed: {
                vatAmount: {
                    get: function () {
                        return this.$store.getters.vatIncreaseAmount;
                    },
                    set: function (newValue) {
                        this.$store.dispatch('setVatIncreaseAmount', newValue)
                    }
                },
                showForm: {
                    get: function () {
                        return this.$store.getters.vatIncreaseForm;
                    },
                    set: function (newValue) {
                        this.$store.dispatch('setVatIncreaseForm', newValue)
                    }
                },
                formAnswers: {
                    get: function () {
                        return this.$store.getters.vatIncreaseAnswers;
                    },
                    set: function (newValue) {
                        this.$store.dispatch('setVatIncreaseAnswers', newValue)
                    }
                },
            },
            mounted: function () {
            },
            methods: {
                outcome: function(one, two, three) {
                    var model = [
                        [2018, 2018, 2018, 6],
                        [2019, 2019, 2019, 9],
                        [2018, 2019, 2019, 6],
                        [2018, 2018, 2019, 6],
                        [2018, 2019, 2018, 6],
                        [2019, 2019, 2018, 9],
                        [2019, 2018, 2019, 9],
                        [2019, 2018, 2018, 6],
                    ];

                    var input = [
                        parseInt(one),
                        parseInt(two),
                        parseInt(three)
                    ];

                    for(let x = 0 ; x < model.length ; x++) {
                        let c = 0;
                        for(let i = 0 ; i < input.length ; i++) {
                            if(model[x][i] == input[i]) {
                                c++;
                            }
                        }
                        if(c == input.length) {
                            return model[x][(model[x].length-1)];
                        }
                    }
                },
                reset: function() {
                    this.vatAmount = null;
                    this.showForm = false;
                },
                submit: function() {
                    let that = this;
                    that.$validator.validateAll().then(result => {
                        if (result) {
                            let formData = new FormData(that.$el);

                            that.formAnswers = {
                                'answer1': formData.get('answer1'),
                                'answer2': formData.get('answer2'),
                                'answer3': formData.get('answer3')
                            };

                            that.vatAmount = this.outcome(
                                formData.get('answer1'),
                                formData.get('answer2'),
                                formData.get('answer3')
                            );
                        }
                    });
                },
                toggleForm: function()
                {
                    this.showForm = (!this.showForm);
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});

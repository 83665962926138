Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("unieregelingWrapper")) {
        const unieregelingWrapper = new Vue({
            el: '#unieregelingWrapper',
            name: 'unieregeling',
            data: function () {
                return {
                    answers: {1: null, 2: null, 3: null, 4: null, 5: null, 6: null, 7: null, 8: null, 9: null},
                    message: null,
                    show: false,
                    hasCaptcha: false,
                    captchaExecuted: false,
                    choice: null,
                }
            },
            components: {
                'vue-recaptcha': VueRecaptcha
            },
            computed: {},
            mounted: function () {
                this.show = true
            },
            methods: {
                changeRadio(number) {
                    // If you already answered later questions, empty them
                    for (const i in this.answers) if (this.answers.hasOwnProperty(i))
                        if (i > number) this.answers[i] = null
                },
                showQuestion(number) {
                    switch (number) {
                        case 2:
                            return this.answers[1] === '1'
                        case 3:
                            return this.answers[2] === '0'
                        case 4:
                            return this.answers[3] === '1'
                        case 5:
                            return this.answers[4] === '1'
                        case 6:
                            return this.answers[3] === '0' || this.answers[4] === '0' || this.answers[5] !== null
                        case 7:
                            return this.answers[6] === '1' || (this.answers[5] === '0' && this.answers[6] === '0')
                        case 8:
                            return this.answers[7] === '0'
                        case 9:
                            return this.answers[7] === '1' || this.answers[8] === '1'
                        default:
                            return this.answers[number - 1] !== null
                    }
                },
                showChoiceText(variant) {
                    switch(variant) {
                        case 1:
                            return this.answers[3] === '0' || this.answers[4] === '0' || this.answers[5] === '1';
                    }
                },
                showMessage(type) {
                    switch (type) {
                        case '1_no':
                            return this.answers[1] === '0'
                        case '2_yes':
                            return this.answers[2] === '1'
                        case '5_yes':
                            return this.answers[5] === '1' && this.answers[6] === '1' && this.answers[8] !== '0'
                        case '6_no':
                            return (this.answers[3] === '0' && this.answers[6] === '0')
                                || (this.answers[4] === '0' && this.answers[6] === '0')
                                || (this.answers[5] === '1' && this.answers[6] === '0')
                        case '8_no':
                            return this.answers[8] === '0'
                        case '8_yes':
                            return this.answers[8] === '1'
                    }
                },
                showConsequences() {
                    return !(
                        this.answers[3] === '0' ||
                        this.answers[4] === '0' ||
                        this.answers[5] === '1'
                    );
                },
                showPointsOfAttention(choice) {
                    switch (choice) {
                        case 1:
                            return this.answers[9] === '1'
                        case 2:
                            return this.answers[9] === '2'
                        default:
                            return this.answers[9] !== null
                    }
                },
                sendGaEvent(data) {
                    // Disable for now
                    //
                    // let category = typeof data.category != 'undefined' ? data.category : 'Algemeen'
                    // let message = typeof data.message != 'undefined' ? data.message : 'Onbekend'
                    //
                    // if (typeof ga === 'function') {
                    //     let trackers = ga.getAll();
                    //     trackers.forEach(function (tracker) {
                    //         ga(tracker.get('name') + '.send', 'event', 'Toolkit Unieregeling', category, message, {
                    //             nonInteraction: true
                    //         })
                    //     })
                    // }
                },
                onCaptchaVerified: function () {
                    this.captchaExecuted = true;
                }
            }
        });
    }
});

let Global = {
    Blazy: function () {
        Global.BlazyInstance = new Blazy({
            breakpoints: [{
                width: 0,
                src: 'data-src-small'
            }, {
                width: 640,
                src: 'data-src-medium'
            }, {
                width: 1024,
                src: 'data-src-large'
            }, {
                width: 1200,
                src: 'data-src-xlarge'
            }, {
                width: 1440,
                src: 'data-src-xxlarge'
            }]
        })
    },
    Barba: function () {
        Barba.Pjax.cacheEnabled = false;

        let FadeTransition = Barba.BaseTransition.extend({
            start: function () {
                // As soon the loading is finished and the old page is faded out, let's fade the new page
                Promise
                    .all([this.newContainerLoading, this.fadeOut()])
                    .then(this.fadeIn.bind(this));
            },

            fadeOut: function () {
                return new Promise((resolve, reject) => {
                    document.querySelector('.barba-bg').className += ' barba-bg--visible';
                    window.setTimeout(function () {
                        resolve();
                    }, 500);
                });
            },

            fadeIn: function () {
                let _this = this;
                (this.oldContainer).style.display = 'none';

                window.scrollTo(0, 0);

                document.querySelector('.barba-bg').classList.remove('barba-bg--visible');
                _this.done();
            }
        });

        Barba.Pjax.getTransition = function () {
            return FadeTransition;
        };
    },

    Vvalidate: function() {
        Vue.use(VeeValidate, {
            locale: 'nl',
            events: 'blur|submit',
            classes: true,
            invalidateFalse: true,
            classNames: {
                invalid: 'input--invalid',
                valid: 'input--valid'
            },
            dictionary: {
                nl: { messages: Global.FormDictionary}
            }
        });
    },

    SubmitForm: function (form, gtmEvent) {
        form.classList.toggle('form--loading');

        if(form.querySelector('.form-message')) {
            form.querySelector('.form-message').remove();
        }

        let messageTemplate =
            '<div class="form-message form-message--active %status%">' +
            '   <div class="form-message__content">%message%</div>' +
            '   <span class="form-message__icon form-message__icon--success">' +
            '       <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z"/></svg>' +
            '   </span>' +
            '   <span class="form-message__icon form-message__icon--error">' +
            '       <svg width="32" height="32" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1088 1248v224q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-224q0-26 19-45t45-19h256q26 0 45 19t19 45zm30-1056l-28 768q-1 26-20.5 45t-45.5 19h-256q-26 0-45.5-19t-20.5-45l-28-768q-1-26 17.5-45t44.5-19h320q26 0 44.5 19t17.5 45z"/></svg>' +
            '   </span>' +
            '</div>';

        let formData = new FormData(form);

        axios({
            method: 'post',
            url: form.action,
            data: formData
        }).then(function (response) {

            if (response.data.target) {
                Barba.Pjax.goTo(response.data.target);
                return;
            }

            messageTemplate = messageTemplate.replace(/%message%/, response.data.message);
            messageTemplate = messageTemplate.replace(/%status%/, 'form-message--success');

            form.innerHTML = messageTemplate;
            form.classList.toggle('form--loading');

            // Sends the event to the Google Analytics property with
            // tracking ID GA_TRACKING_ID set by the config command in
            // the global tracking snippet.
            if (window.gtag !== undefined) {
                gtag('event', 'Ingevuld', {
                    'event_category': (gtmEvent !== undefined ? gtmEvent : 'Formulierenbeheer')
                });
            }

            Listeners.MoveToInstance.move(document.querySelector('.form-message'));

        }).catch(function (error) {
            if (error.response !== undefined) {
                messageTemplate = messageTemplate.replace(/%message%/, error.response.data.message);
                messageTemplate = messageTemplate.replace(/%status%/, 'form-message--error');

                form.classList.toggle('form--loading');
                form.insertAdjacentHTML('afterbegin', messageTemplate);
                Listeners.MoveToInstance.move(document.querySelector('.form-message'));
            } else {
                //console.error(error);
                Listeners.MoveToInstance.move(document.querySelector('.form-message'));
            }
        });
    },
    FormDictionary: {
        _default: (field) => `Dit veld is niet correct ingevuld.`,
        after: (field, [target, inclusion]) => `Dit veld moet groter ${inclusion ? 'of gelijk aan ' : ''} ${target}.`,
        alpha_dash: (field) => `Dit veld mag alleen alfanumerieke karakters, strepen en onderstrepingstekenen bevatten.`,
        alpha_num: (field) => `Dit veld mag alleen alfanumerieke karakters bevatten.`,
        alpha_spaces: (field) => `Dit veld mag alleen alfanumerieke karakters en spaties bevatten.`,
        alpha: (field) => `Dit veld mag alleen alfabetische karakters bevatten.`,
        before: (field, [target, inclusion]) => `De waarde van dit veld moet kleiner ${inclusion ? 'of gelijk aan' : ''} ${target} zijn.`,
        between: (field, [min, max]) => `De waarde van dit veld moet tussen ${min} en ${max} zijn.`,
        confirmed: (field) => `Dit bevestigingsveld komt niet overeen.`,
        credit_card: (field) => `Dit veld is ongeldig.`,
        date_between: (field, [min, max]) => `De datum moet tussen ${min} en ${max} zijn.`,
        date_format: (field, [format]) => `Dit veld moet het volgende formaat hebben: ${format}.`,
        decimal: (field, [decimals = '*'] = []) => `Dit veld mag alleen numerieke, en${!decimals || decimals === '*' ? ' ' : decimals}decimale nummers bevatten.`,
        digits: (field, [length]) => `Dit veld moet ${length} nummers bevatten.`,
        dimensions: (field, [width, height]) => `De dimensies voor dit veld moet ${width} pixels breed en ${height} pixels hoog zijn.`,
        email: (field) => `Dit veld moet een geldig e-mailadres bevatten.`,
        ext: (field) => `Dit veld moet een correct bestand bevatten.`,
        image: (field) => `Dit veld moet een afbeelding bevatten.`,
        included: (field) => `Dit veld moet een geldige waarde bevatten.`,
        integer: (field) => `Dit veld moet een nummer zijn.`,
        ip: (field) => `Dit veld moet een veilig ip adres zijn.`,
        length: (field, [length, max]) => {
            if (max) {
                return `Dit veld moet minimaal ${length} karakters en maximaal ${max} karakters bevatten.`;
            }

            return `Dit veld moet minimaal ${length} karakters lang zijn.`;
        },
        max: (field, [length]) => `Dit veld mag niet meer karakters bevatten dan ${length}.`,
        max_value: (field, [max]) => `Dit veld moet ${max} karakters of minder bevatten.`,
        mimes: (field) => `Dit veld moet Dit juiste type bestand bevatten.`,
        min: (field, [length]) => `Dit veld moet minimaal ${length} karakters zijn.`,
        min_value: (field, [min]) => `Dit veld moet minimaal ${min} karakters zijn.`,
        excluded: (field) => `Dit veld moet een geldige waarde bevatten`,
        numeric: (field) => `Dit veld mag alleen numerieke karakters bevatten.`,
        regex: (field) => `Dit veld is niet correct ingevoerd.`,
        required: (field) => `Dit veld is verplicht.`,
        size: (field, [size]) => `De bestandsgrootte van dit veld mag niet groter zijn dan ${formatFileSize(size)}.`,
        url: (field) => `Dit veld moet een valide URL zijn.`
    },
    CheckboxFocus: function() {
        // Specific code to auto blur (unfocus) in forms
        var checkboxes = document.querySelectorAll("input[type='checkbox']");
        checkboxes.forEach(function(item) {
            item.addEventListener("change", unFocus);
        });
    },
    Init: function () {
        Global.Blazy();
        Global.Barba();
        Global.Vvalidate();
        Global.CheckboxFocus();
    }
};

// ignore download links
Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;
Barba.Pjax.preventCheck = function(evt, element) {
    if (!Barba.Pjax.originalPreventCheck(evt, element)) {
        return false;
    }

    // ignore pdf links
    if (/download.php/.test(element.href.toLowerCase())) {
        return false;
    }

    return true;
};

document.addEventListener("DOMContentLoaded", function () {
    Barba.Pjax.start();
    Global.CheckboxFocus();
});

Barba.Dispatcher.on("transitionCompleted", function () {
    Global.Init();
});

/**
 * Toevoeging voor het correct tracken in analytics
 *
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
 */
Barba.Dispatcher.on('newPageReady', function () {
    if (Barba.HistoryManager.history.length <= 1) {
        return;
    }

    // send statics by Google Analytics(analytics.js) or Google Tag Manager
    if (typeof ga === 'function' && typeof ga.getByName === 'function') {
        let tracker = ga.getByName('webnlPageview');
        if(typeof tracker !== 'undefined') {
            ga(tracker.get('name') + '.set', 'page', location.pathname);
            ga(tracker.get('name') + '.send', 'pageview');
        }
    }
});

function unFocus() {
    this.blur();
};

function touchMenu() {
    // Specific code for iPad submenu's fix with touch
    if (this.classList.contains('hover')) {
        return true;
    } else {
        event.preventDefault();

        var menuItems = document.querySelectorAll(".header__listLink--with-sub-nav");
        menuItems.forEach(function(item) {
            item.classList.remove('hover');
            item.parentElement.classList.remove('hover');
        });
        this.classList.add('hover');
        this.parentElement.classList.add('hover');
    }
};

Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("calculator")) {
        const calculator = new Vue({
            name: 'calculator',
            el: '#calculator',
            store,
            data: function () {
                return {
                    loading: false,
                    error: false,
                    results: null
                }
            },
            computed: {
                diffIsAboveZero: function()
                {
                    return (this.results != null && this.results['O74'].value > 0);
                },
                diffIsBelowZero: function()
                {
                    return (this.results != null && this.results['O74'].value < 0);
                }
            },
            mounted: function () {
                this.reset();
            },
            methods: {
                reset: function() {
                    this.loading = false;
                    this.error = false;
                    this.results = null;
                },
                submit: function () {
                    let that = this;
                    that.$validator.validateAll().then(result => {
                        if (result) {
                            that.reset();
                            that.loading = true;

                            let formData = new FormData(that.$el);

                            axios({
                                method: 'post',
                                url: that.$el.action,
                                data: formData
                            }).then(function (response) {
                                that.results = response.data.results;
                                that.loading = false;
                            }).catch(function (error) {
                                if (error.response !== undefined) {
                                    that.error = error.response.data.message;
                                }
                                that.loading = false;
                            });
                        }
                    });
                },
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});

Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("header")) {
        const header = new Vue({
            name: 'header',
            el: '#header',
            store,
            data: function () {
                return {
                    scrolled: false,
                    showSearch: false
                }
            },
            computed: {
                menuExpanded: {
                    get: function () {
                        return this.$store.getters.menuExpanded
                    },
                    set: function (newValue) {
                        this.$store.dispatch('toggleMenu', newValue)
                    }
                }
            },
            mounted: function () {
                let that = this;
                window.addEventListener('scroll', function () {
                    that.watchScrollPosition();
                });

                this.watchScrollPosition();
                this.resetExpandedMenu();
                this.menuTouchable();
            },
            methods: {
                resetExpandedMenu: function () {
                    this.menuExpanded = false;
                    document.body.classList.remove('body--overflowHidden');
                },
                toggleMenu: function () {
                    this.menuExpanded = !this.menuExpanded;
                    document.body.classList.toggle('body--overflowHidden');
                },
                watchScrollPosition: function () {
                    let that = this;
                    const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

                    if (scrollTop > 50) {
                        that.scrolled = true;
                    } else {
                        that.scrolled = false;
                    }
                },
                openSearch: function() {
                    let that = this;

                    this.showSearch = true;
                    this.$refs.search.focus();
                },
                menuTouchable: function() {
                    // Specific code for iPad submenu's fix with touch
                    var menuItems = document.querySelectorAll(".header__listLink--with-sub-nav");
                    menuItems.forEach(function(item) {
                        item.addEventListener("touchstart", touchMenu);
                    });
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});

Vue.component("unieregeling-radio-element", {
    name: 'unieregeling-radio',
    data: function () {
        return {
            active: false,
            show: false,
        }
    },
    props: ['section', 'number', 'value'],
    computed: {
        // From: https://stackoverflow.com/questions/47311936/v-model-and-child-components
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    mounted: function () {
        this.show = true
    },
    methods: {
        toggleQuestion: function() {
            this.active = !this.active
        },
        changeRadio: function() {
            this.$emit('change-radio', this.number);
        },
        showAnswer() {

        }
    }
});

Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("team-slider")) {
        const teamSlider = new Vue({
            name: 'team-slider',
            el: '#team-slider',
            store,
            data: function () {
                return {
                    slider: null
                }
            },
            computed: {},
            mounted: function () {
                this.initializeSlider();
            },
            methods: {
                initializeSlider: function () {
                    let that = this;

                    let elem = document.querySelector(".team-slider");
                    this.slider = new Flickity(elem, {
                        contain: true,
                        pageDots: false,
                        imagesLoaded: true,
                        wrapAround: true,
                        prevNextButtons: false,
                        lazyLoad: 2
                    });
                },
                nextSlider: function() {
                    this.slider.next(true, false);
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});

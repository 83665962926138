Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("share")) {
        const share = new Vue({
            name: 'share',
            el: '#share',
            store,
            data: function () {
                return {}
            },
            computed: {},
            methods: {
                copyUrl: function(str, event) {
                    event.preventDefault();
                    const el = document.createElement('textarea');
                    el.value = str;
                    el.setAttribute('readonly', '');
                    el.style.position = 'absolute';
                    el.style.left = '-9999px';
                    document.body.appendChild(el);
                    el.select();
                    document.execCommand('copy');
                    document.body.removeChild(el);
                    event.currentTarget.querySelector('.share-sidebar__text').innerHTML = 'Gekopiëerd';
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});


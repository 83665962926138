Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("employees-team-display")) {
        const employees = new Vue({
            name: 'employees-team-display',
            el: '#employees-team-display',
            store,
            methods: {
                showEmployees() {
                    const cards = document.querySelectorAll('.d-none.employee--card');
                    cards.forEach(card => {
                        card.classList.remove('d-none')
                    })

                    const buttons = document.querySelectorAll('.js-employee-button');
                    buttons.forEach(button => {
                        setTimeout(function() {
                            button.classList.add('d-none')
                        }, 300)
                    });
                },
            },
        });
    }
});

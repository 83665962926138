let Listeners = {
    MoveToInstance: new MoveTo({
        tolerance: 120,
        duration: 800
    }),
    SmoothScroll: function() {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            if(anchor.getAttribute('href').length > 1) {
                anchor.addEventListener('click', function (e) {
                    e.preventDefault();

                    let target = document.querySelector(this.getAttribute('href'));
                    Listeners.MoveToInstance.move(target);
                });
            }
        });
    },
    Init: function () {
        Listeners.SmoothScroll();
    }
};

Barba.Dispatcher.on("transitionCompleted", function () {
    Listeners.Init();
});
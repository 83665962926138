Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("scroll-spy-nav")) {
        const scrollSpyNav = new Vue({
            name: 'scroll-spy-nav',
            el: '#scroll-spy-nav',
            store,
            data: function () {
                return {
                    scrollPosition: null,
                    scrollElems: {},
                    heroHeight: null,
                    headerHeight: 80
                }
            },
            computed: {},
            mounted: function () {
                let that = this;
                this.setScrollSpyData();
                this.setHeroHeight();

                window.onresize = function () {
                    that.setScrollSpyData();
                    that.setHeroHeight();
                };

                let delayed = false;
                window.addEventListener('scroll', function () {
                    if (!delayed) {
                        that.initializeScrollSpy();
                        that.setScrollSpyPosition();
                        delayed = true;

                        setTimeout(function () {
                            delayed = false;
                        }, 50);
                    }
                });
            },
            methods: {
                setScrollSpyData: function () {
                    let that = this;
                    this.scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
                    let scrollElements = document.querySelectorAll(".scroll-section");

                    let i = 0;
                    scrollElements.forEach(e => {
                        that.scrollElems[i] = {
                            "id": e.id,
                            "offsetTop": e.offsetTop
                        };
                        i++;
                    });
                },
                setScrollSpyPosition: function () {
                    let that = this;
                    if (document.getElementById("scroll-spy-nav")) {
                        this.scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

                        if (this.scrollPosition > (this.heroHeight - 260)) {
                            document.getElementById("scroll-spy-nav").classList.add("scroll-spy--active");
                        } else {
                            document.getElementById("scroll-spy-nav").classList.remove("scroll-spy--active");
                        }
                    }
                },
                setHeroHeight: function () {
                    this.heroHeight = document.querySelector('.hero').clientHeight;
                },
                initializeScrollSpy: function () {
                    if (document.getElementById("scroll-spy-nav")) {
                        let that = this;
                        this.scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

                        for (let i in this.scrollElems) {
                            if (that.scrollElems[i].offsetTop <= that.scrollPosition) {
                                document.querySelector('.scroll-spy__link--active').classList.remove('scroll-spy__link--active');
                                document.querySelector('a[href="#' + that.scrollElems[i].id + '"]').classList.add('scroll-spy__link--active');
                            }
                        }
                    }
                },
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});

Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("expandable-text")) {
        const expandableText = new Vue({
            name: 'expandable-text',
            el: '#expandable-text',
            store,
            data: function () {
                return {
                    textExpanded: false,
                    textExpandedCompleted: false
                }
            },
            watch: {
                textExpanded: function () {
                    this.toggleTextExpanded();
                }
            },
            computed: {},
            mounted: function () {
                let that = this;

                window.addEventListener('resize', function () {
                    that.toggleTextExpanded();
                });
            },
            methods: {
                toggleTextExpanded: function () {
                    let that = this;

                    if (this.textExpanded) {
                        let content = document.getElementById('expandable-text').querySelector(".expandable-text__text");

                        if (that.textExpandedCompleted) {
                            content.style.height = 'auto';
                        }

                        content.style.height = content.scrollHeight + "px";
                        that.textExpandedCompleted = true;
                    }
                },
                created: function () {
                },
                destroyed: function () {
                }
            }
        });
    }
});

Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("overlayVideo")) {
        const overlay = new Vue({
            el: '#overlayVideo',
            store,
            data: function () {
                return {
                    componentName: 'overlay-video',
                    video: {},
                    showOverlay: false,
                }
            },
            methods: {
                closeOverlay() {
                    document.getElementsByTagName('body')[0].style.overflow = 'visible';
                    document.getElementById('overlayVideo').style.display = 'none';
                    document.getElementById('header').style.opacity = '1';
                    localStorage.setItem('hideOverlayVideo', 'true')
                },
                initiateVideos: function () {
                    let that = this;

                    this.video.instance = videojs('#overlay-video', {
                        controls: true,
                        fluid: true,
                        aspectRatio: '16:9',
                        techOrder: ["html5", "Youtube"],
                    });

                    Barba.Dispatcher.on("linkClicked", function () {
                        setTimeout(() => {
                            that.video.instance.dispose();
                        }, 100);
                    });
                },
                toggleVideo: function (playerID) {
                    let elem = document.getElementById(playerID).parentElement;
                    elem.classList.toggle('video__wrapper--playing');

                    if (!this.video.playing) {
                        this.video.instance.play();
                        this.video.instance.controls(true);
                        this.video.playing = true;
                    } else {
                        this.video.instance.pause();
                        this.video.instance.controls(false);
                        this.video.playing = false;
                    }
                },
            },
            mounted() {
                this.showOverlay = localStorage.getItem('hideOverlayVideo') !== 'true' || localStorage.getItem('hideOverlayVideo') === null

                if (this.showOverlay) {
                    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
                    document.getElementById('overlayVideo').style.display = 'flex';
                    document.getElementById('header').style.opacity = '0';
                }

                this.initiateVideos();
            }
        });
    }
});

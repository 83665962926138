Barba.Dispatcher.on("transitionCompleted", function () {
    if (document.getElementById("vatincrease-form")) {
        const vatincreaseForm = new Vue({
            name: 'vatincrease-form',
            el: '#vatincrease-form',
            store,
            data: function () {
                return {
                }
            },
            computed: {
                vatAmount: {
                    get: function () {
                        return this.$store.getters.vatIncreaseAmount;
                    },
                    set: function (newValue) {
                        this.$store.dispatch('setVatIncreaseAmount', newValue)
                    }
                },
                showForm: {
                    get: function () {
                        return this.$store.getters.vatIncreaseForm;
                    },
                    set: function (newValue) {
                        this.$store.dispatch('setVatIncreaseForm', newValue)
                    }
                },
                formAnswers: {
                    get: function () {
                        return this.$store.getters.vatIncreaseAnswers;
                    },
                    set: function (newValue) {
                        this.$store.dispatch('setVatIncreaseAnswers', newValue)
                    }
                },
            },
            mounted: function () {
            },
            methods: {
                submit: function() {
                    let that = this;
                    that.$validator.validateAll().then(result => {
                        if (result) {
                            Global.SubmitForm(that.$el, 'BTW Verhoging');
                        }
                    });
                }
            },
            created: function () {
            },
            destroyed: function () {
            }
        });
    }
});

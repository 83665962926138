Vue.component("kor-radio-element", {
    name: 'KOR-radio',
    data: function () {
        return {
            active: false,
            show: false,
        }
    },
    props: {
        section: Number,
        question: Number,
        number: Number,
    },
    mounted: function () {
        this.show = true
    },
    methods: {
        toggleQuestion: function() {
            this.active = !this.active
        },
        checkQuestion: function(e) {
            let value = e.srcElement.value
            if(this.number == 1 || value == 0) {
                let activeSection = this.number == 4 ? this.section + 1 : this.section

                if(this.number == 1 && this.section == 3) {
                    // Is OVOB compliant
                    let activeMessage = true
                    if(value == 0) {
                        activeSection += 1
                        activeMessage = ''
                    }

                    this.$emit('updatedata', {
                        section: activeSection,
                        message: activeMessage,
                    });

                    this.$emit('sendgaevent', {
                        category: 'Vergelijkbare situatie',
                        message: 'Voldoet aan OVOB regeling'
                    });
                } else {
                    this.$emit('updatedata', {
                        section: activeSection,
                        question: this.number,
                        message: '',
                    });
                }
            } else {
                // Is not OVOB compliant
                this.$emit('updatedata', {
                    section: this.section,
                    question: this.number - 1,
                    message: false,
                });

                this.$emit('sendgaevent', {
                    category: 'Voorvragen',
                    message: 'Voldoet niet aan OVOB regeling'
                });
            }
        },
    }
});

Vue.component("accordion", {
    name: 'accordion',
    data: function() {
        return {
            activePanel: null,
        };
    },
    computed: {},
    methods: {
        makeActive(itemIndex) {
            this.activePanel === itemIndex
                ? (this.activePanel = null)
                : (this.activePanel = itemIndex);
        },
        beforeEnter: function(el) {
            el.style.height = "0";
        },
        enter: function(el) {
            el.style.height = el.scrollHeight + "px";
        },
        afterEnter: (el) => {
            el.style.height = "auto";
        },
        beforeLeave: function(el) {
            el.style.height = el.scrollHeight + "px";
        },
        leave: function(el) {
            setTimeout(() => {
                el.style.height = "0";
            }, 50)
        }
    },
    mounted() {
        // this.activePanel = 1;
    }
});

Vue.component("collapse-transition", {});
